import { render, staticRenderFns } from "./InvoiceImage.vue?vue&type=template&id=631c111f&scoped=true"
import script from "./InvoiceImage.vue?vue&type=script&lang=js"
export * from "./InvoiceImage.vue?vue&type=script&lang=js"
import style0 from "./InvoiceImage.vue?vue&type=style&index=0&id=631c111f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631c111f",
  null
  
)

export default component.exports